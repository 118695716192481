export const CARD_CONTENT = [
    {
        title: "Same Day",
        subtitle: "Entregas en el mismo día."
    },
    {
        title: "Next Day",
        subtitle: "Buscamos tu paquete hoy y lo entregamos mañana."
    },
    {
        title: "Programado",
        subtitle: "Elige la mejor opción horaria de entrega en función de tu cliente."
    }
]