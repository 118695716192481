import React, { useState, useEffect } from 'react';
import styles from './Nav.module.scss';
import './NavMobile.scss';
import { useMediaQuery } from 'react-responsive'
import { NavLink } from 'react-router-dom';
import { HashLink } from "react-router-hash-link";
import { NavProps } from './types';
import { Link } from 'react-router-dom';

import logo from '@assets/logo.png';

const links = [
    { text: "Rastrea tu envío", href: "/tracking" },
    { text: "Conócenos", href: "/#conocenos" },
    { text: "Servicios", href: "/#servicios" },
    { text: "FAQ", href: "/faq" },
]

const Nav = ({ reduced }: NavProps) => {
    const [scrollPosition, setSrollPosition] = useState(0);
    const [scrollingUp, setScrollingUp] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const isMobile = useMediaQuery({
        query: '(max-width: 820px)'
    })

    const handleScroll = () => {
        const position = window.pageYOffset;
        setSrollPosition(position)
        position < scrollPosition ? setScrollingUp(true) : setScrollingUp(false)
    };

    const toggleMenu = () => {
        isOpen ? setIsOpen(false) : setIsOpen(true)
    }


    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    });

    const getComponent = (link: any) => {
        let C: any = <NavLink className={styles.Link} to={link.href}>{link.text}</NavLink>
        if (link.href.includes("#"))
            C = <HashLink className={styles.Link} to={link.href} smooth>{link.text}</HashLink>
        return C;
    }

    return (
        <>
            {!isMobile &&
                <header className={`${styles.Nav} ${!scrollingUp && scrollPosition > 50 ? styles.hiddenNav : ""} ${scrollingUp && scrollPosition > 50 ? styles.ScrolledNav : ""}`}>
                    <nav>
                        <NavLink key={"logo"} to={"/"} className={styles.Logo}>
                            <img src={logo} alt="logo" />
                        </NavLink>
                        {!reduced ?
                            <ul className={styles.LinksContainer}>
                                {links.map((link, index) => (<li key={index}>{getComponent(link)}</li>))}
                            </ul>
                            :
                            <Link to="#ayuda" className={styles.Link}>
                                ¿Necesitas ayuda?
                            </Link>
                        }
                    </nav>
                </header>}

            {isMobile && (
                <header className={`${!scrollingUp && scrollPosition > 50 && !isOpen ? "hiddenNav" : ""} ${"isMobile"} ${scrollingUp && scrollPosition > 50 ? "ScrolledNav" : ""} ${isOpen ? "navOpen" : ""}`}>
                    <div className={"NavWrapper"}>
                        <NavLink key="logo" to="/" className="Logo" onClick={() => setIsOpen(false)}>
                            <img src={logo} alt="logo" />
                        </NavLink>
                        <div className={`burger ${isOpen ? "burgerOpen" : ""}`} onClick={toggleMenu}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>

                    <nav className={`NavLinks navbar ${isOpen ? "navOpen" : ""}`}>
                        {isOpen && <ul className={"navLinks"}>
                            {links.map((link, index) => (<li key={index} className={`${isOpen ? "navLinkOpen" : ""}`} onClick={toggleMenu}>{getComponent(link)}</li>))}
                        </ul>}
                    </nav>
                </header>
            )
            }
        </>
    )
}

export default Nav;