import { Grid, Typography } from '@mui/material';
import MainWrapper from '@organisms/MainWrapper';
import React, { FC } from 'react';

import styles from './styles.module.scss';
import Icon from '@atoms/Icon';
import { CARD_CONTENT } from './constants';
import Card from '@molecules/Card';
import { isMobile } from 'react-device-detect';

const Conocenos: FC = () => {
    return (
        <MainWrapper
            spacing={6}
            className={styles.conocenos}
            container justifyContent='center'
            alignItems="center"
            direction="column"
            id="conocenos"
            hasPadding
        >
            <Grid item>
                <Typography variant='h2Bold' align='center'>
                    Confía en Rayo
                </Typography>
            </Grid>
            <Grid item justifyContent="center" >
                <Grid container justifyContent="center" direction="column" spacing={2}>
                    <Grid item direction="row" className={styles.TextCard}>
                        <Icon name="CloudUploadOutlined" backgroundColor="primary" />
                        <Typography variant="h4">Fácil integración con tu tienda</Typography>
                    </Grid>
                    <Grid item direction="row" className={styles.TextCard}>
                        <Icon name="MyLocationOutlined" backgroundColor="primary" />
                        <Typography variant="h4">Controla todo el proceso de tus envíos</Typography>
                    </Grid>
                    <Grid item direction="row" className={styles.TextCard}>
                        <Icon name="PaymentsOutlined" backgroundColor="primary" />
                        <Typography variant="h4">Tarifas competitivas</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item sx={{ paddingTop: isMobile ? '2rem' : '10rem' }}>
                <Grid container justifyContent="center" direction="column" spacing={2}>
                    <Grid item sx={{ textAlign: 'center' }} marginTop={"3rem"}>
                        <Typography variant='h2Bold' align='center' >
                            Haz crecer tus operaciones con nuestros distintos tipos de envíos
                        </Typography>
                    </Grid>
                    <Grid item className={styles.Cards}>
                        {CARD_CONTENT.map((card, index) => <Card key={index} title={card.title} subtitle={card.subtitle} />)}
                    </Grid>
                </Grid>
            </Grid>
        </MainWrapper>
    )
}

export default Conocenos