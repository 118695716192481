import { FC } from 'react';

import Carrousel from '@molecules/Carrousel';

import Conocenos from '@pages/Conocenos';
import Servicios from '@pages/Servicios';

import styles from './styles.module.scss'

import img1 from '@assets/images/slider_home/3.jpeg';
import img2 from '@assets/images/slider_home/2.jpeg';
import img3 from '@assets/images/slider_home/1.jpeg';

import { Button, Grid, Typography } from '@mui/material';
import MainWrapper from '@organisms/MainWrapper';
import { isMobile } from 'react-device-detect';
import { HashLink } from "react-router-hash-link";

const Home: FC = () => {
    return (
        <>
            <MainWrapper className={styles.Home}>
                <main className={styles.Carousel}>
                    <Carrousel
                        autoplay
                        showArrows={!isMobile}
                    >
                        <Grid className={styles.Carousel__Item} style={{ backgroundImage: `url(${img1})` }}>
                            <Grid className={styles.Carousel__Container}>
                                <Typography variant='h1' className={styles.Carousel__Title}>Logística y envíos<br />para compras online</Typography>
                                <HashLink to="/#conocenos" smooth>
                                    <Button variant='contained'>
                                        Ver más
                                    </Button>
                                </HashLink>
                            </Grid>
                        </Grid>

                        <Grid className={styles.Carousel__Item} style={{ backgroundImage: `url(${img2})` }}>
                            <Grid className={styles.Carousel__Container}>
                                <Typography variant='h1' className={styles.Carousel__Title}>Solución de última milla<br /> para ecommerce y retail</Typography>
                                <HashLink to="/#conocenos" smooth>
                                    <Button variant='contained'>
                                        Ver más
                                    </Button>
                                </HashLink>
                            </Grid>
                        </Grid>

                        <Grid className={styles.Carousel__Item} style={{ backgroundImage: `url(${img3})` }}>
                            <Grid className={styles.Carousel__Container}>
                                <Typography variant='h1' className={styles.Carousel__Title}>Entregamos tus productos<br /> en el menor tiempo</Typography>
                                <HashLink to="/#conocenos" smooth>
                                    <Button variant='contained'>
                                        Ver más
                                    </Button>
                                </HashLink>
                            </Grid>
                        </Grid>
                    </Carrousel>
                </main>
                <Conocenos />
                <Servicios />
            </MainWrapper>
        </>
    )
}

export default Home